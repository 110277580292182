import React from "react";
import * as ReactDOM from "react-dom";
import Toastr from "../components/toastr/toastr";

const mountComponent = (type: string, message: string) => {
  // Create target to attach component to DOM
  let mountingPoint = document.querySelector(".toastr-mount");
  if (!mountingPoint) {
    mountingPoint = document.createElement("div");
    mountingPoint.className = "toastr-mount";
    document.body.append(mountingPoint);
  } else {
    mountingPoint.parentElement.removeChild(mountingPoint);
    mountComponent(type, message);
  }

  // Mount the component
  ReactDOM.render(
    <Toastr {...{ type, message }} />,
    mountingPoint,
  );
};

window.toastr = {
  success: (message) => mountComponent("success", message),
  error: (message) => mountComponent("error", message),
  warning: (message) => mountComponent("warning", message),
  info: (message) => mountComponent("info", message),
}
