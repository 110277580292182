import * as sessionReplay from "@amplitude/session-replay-browser";
import Cookies from "js-cookie";
import { getEnvironment, getAmplitudeApiKey } from "./user_attributes";

const AMPLITUDE_API_KEY = getAmplitudeApiKey();
const AMPLITUDE_COOKIE_KEY = "amplitude_session_id";
const SEGMENT_COOKIE_KEY = "analytics_session_id";

const getStoredSessionId = (KEY = AMPLITUDE_COOKIE_KEY) =>
  Cookies.get(KEY) || 0;

// 100% sampling rate for all envs except production (0.1)
const getSampleRate = (env = getEnvironment()) =>
  env === "production" ? 0.1 : 1;

// https://www.docs.developers.amplitude.com/session-replay/sdks/standalone/#amplitude-classic-destination-cloud-mode
const attachSessionReplay = async () => {
  const segmentAnalytics = window.analytics;

  const user = await segmentAnalytics.user();
  const storedSessionId = getStoredSessionId();
  await sessionReplay.init(AMPLITUDE_API_KEY, {
    sessionId: storedSessionId,
    deviceId: user.anonymousId(),
    sampleRate: getSampleRate(),
  }).promise;

  // Add middleware to check if the session id has changed,
  // and update the session replay instance
  segmentAnalytics.addSourceMiddleware(({ payload, next }) => {
    const storedSessionId = getStoredSessionId();
    const nextSessionId = Cookies.get(SEGMENT_COOKIE_KEY) || 0;

    if (storedSessionId < nextSessionId) {
      Cookies.set(AMPLITUDE_COOKIE_KEY, nextSessionId);
      sessionReplay.setSessionId(nextSessionId);
    }
    next(payload);
  });

  // Add middleware to add session replay properties to track calls
  segmentAnalytics.addSourceMiddleware(({ payload, next }) => {
    const sessionReplayProperties = sessionReplay.getSessionReplayProperties();
    if (payload.type() === "track") {
      payload.obj.properties = {
        ...payload.obj.properties,
        ...sessionReplayProperties,
      };
    }

    next(payload);
  });
};

const acceptableEnvironments = ["production", "staging", "uat"];
export default () => {
  if (typeof window.analytics !== "object") {
    return;
  }

  if (acceptableEnvironments.includes(getEnvironment())) {
    // https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#ready
    window.analytics.ready(attachSessionReplay);
  }
};
