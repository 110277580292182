document.addEventListener("turbolinks:load", () => {
  document.querySelectorAll(".calculates-sales-tax").forEach((field) => addSalesTaxCalculator(field));
});

const addSalesTaxCalculator = (field) => {
  field.addEventListener("change", (event) => {
    const including = document.querySelector(`input[name^='sales_tax_filing[${field.dataset.amountIncludingSalesTaxField}']`);
    including.value = Number(field.value) * field.dataset.salesTaxToAmountIncludingConverter;
  });
};
